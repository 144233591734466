import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/AuthView.vue"),
    meta: {
      title: "Inicia sesión | Empresarios - Taqui",
    },
  },
  {
    path: "/resetPass",
    name: "resetPass",
    component: () => import("../views/ResetPassView.vue"),
    meta: {
      title: "Restablece tu contraseña | Empresarios - Taqui",
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/DashboardView.vue"),
        meta: {
          title: "Inicio | Empresarios - Taqui",
        },
      },
      {
        path: "/reportes",
        name: "reportes",
        component: () => import("../views/ReportesView.vue"),
        meta: {
          title: "Reportes | Empresarios - Taqui",
        },
      },
      {
        path: "/abonos",
        name: "abonos",
        component: () => import("../views/ReportesAbonosView.vue"),
        meta: {
          title: "Reportes de abonos | Empresarios - Taqui",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? "Empresarios - Taqui";
});

export default router;

<template>
  <div class="text-center mx-auto">
    <div class="loadingio-spinner-ripple-epegtbzitnw">
      <div class="ldio-cuwkhf7u04v">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingXs'
}
</script>
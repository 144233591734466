<template>
  <div class="loading_full">
    <div class="loader"></div>
  </div>
</template>
<style scoped>
/* Absolute Center Spinner */
.loading_full {
  position: fixed;
  z-index: 1999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading_full:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(227, 227, 227, 0.8),
    rgba(227, 227, 227, 0.8)
  );

  background: -webkit-radial-gradient(
    rgba(243, 251, 255, 0.8),
    rgba(227, 227, 227, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading_full:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #f3973e;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
